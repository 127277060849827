.footer {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #DDBEA9;
  bottom: 0;
  height: 50px;
}

.footer-icon-container {
  margin-right: 50px;
  display: flex;
  align-items: center;
}

.footer-text {
  font-family: 'Jost', sans-serif;
  font-size: 20px;
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px #CB997E;
  margin-left: 18px;

}

.footer-icon {
  width: 30px;
  margin-left: 10px;
}

/* MOBILE BREAKPOINT */

@media only screen and (max-width: 600px) {

  .footer {
    display: flex;
    justify-content: center;
  }

}
