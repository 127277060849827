.contact {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  background-color: #CB997E;
  font-family: 'Jost', sans-serif;
  padding-top: 100px;
}

.contact-section {
  margin: 0 auto;
  margin-top: 50px;
  width: 500px;
  background-color: #DDBEA9;
}

.contact-form {
  padding: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 400px;
}

.contact-label {
  font-size: 18px;
  font-weight: bold;
  color: white;
  padding-bottom: 5px;
}

.input {
  margin-bottom: 20px;
}

.submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  background-color: #FFE8D6;
  padding: 10px 0px 10px 0px;
  border: none;
  border-radius: 40px;
  color:#CB997E;
  text-decoration: none;
  font-weight: bold;
  text-shadow: 2px 2px white;
  font-size: 16px;
  margin: 0 auto;
}

.submit-button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .contact {
    padding-top: 50px;
  }

  .contact-section {
    margin: 0 auto;
    margin-top: 50px;
    width: 90%;
    background-color: #DDBEA9;
  }
  
  .contact-form {
    padding: 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 80%;
  }
  
  .contact-label {
    font-size: 18px;
    font-weight: bold;
    color: white;
    padding-bottom: 5px;
    width: 100%;
  }
  
  .input {
    margin-bottom: 20px;
    width: 100%;
  }
  
  .submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    background-color: #FFE8D6;
    padding: 10px 0px 10px 0px;
    border: none;
    border-radius: 40px;
    color:#CB997E;
    text-decoration: none;
    font-weight: bold;
    text-shadow: 2px 2px white;
    font-size: 16px;
    margin: 0 auto;
  }

}