.navbar {
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  top: 0;
  right: 0px;
  height: 50px;
  font-family: 'Jost', sans-serif;
  background-color: #DDBEA9;
  z-index: 5;
}

.nav {
  margin-right: 25px;
  font-size: 20px;
  font-weight: 500;
  color: #aa9b92;
  text-decoration: none;
}

.nav:hover{
  cursor: pointer;
  text-decoration: underline;
}

/* MOBILE BREAKPOINT */

@media only screen and (max-width: 600px) {

  .navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 45px;
    font-family: 'Jost', sans-serif;
    background-color: #DDBEA9;
    z-index: 5;
  }
  
  .nav {
    margin-right: 0px;
    font-size: 15px;
  }

  .navend {
    padding-left: 10px;
    padding-right: 10px;
  }

}