.project-details {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
  background-color: #CB997E;
  font-family: 'Jost', sans-serif;
  padding-top: 100px;
}

.proj-name {
  color: white;
  font-size: 100px;
  line-height: 100px;
  text-shadow: 2px 2px #DDBEA9;
  margin-bottom: 0px;
  text-transform: uppercase;
  text-align: center;
}

.project-details-container {
  height: 100%;
  display: flex;
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  padding-bottom: 10px;
}

.column {
  width: 50%;
}

#mobile-img {
  width: 70%;
  box-shadow: 0px 0px 3px 1px #FFE8D6;
}

.subtext {
  color:  #FFE8D6;
  font-size: 24px;
}

.text {
  margin-top: 0px;
  color: white;
  font-size: 20px;
}

.details-button-container {
 display: flex;
 justify-content: space-between;
 margin-top: 40px;
}

.details-button-containers {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  background-color: #FFE8D6;
  padding: 10px 0px 10px 0px;
  border: 1px solid;
  border-radius: 40px;
  color:#CB997E;
  text-decoration: none;
  font-weight: bold;
  text-shadow: 2px 2px white;
}

.details-button-containers:hover {
  font-size: large;
}

#github {
  width: 30px;
  margin-left: 8px;
}

#external-link {
  width: 25px;
  margin-left: 8px;
}


/* MOBILE BREAKPOINT */

@media only screen and (max-width: 600px) {
  .project-details {
    padding-top: 50px;
  }
  
  .proj-name {
    font-size: 50px;
    line-height: 80px;
  }
  
  .project-details-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .column {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .text {
    color: white;
    font-size: 20px;
    text-align: justify;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .details-button-container {
   display: flex;
   justify-content: space-between;
   flex-direction: column;
   margin-top: 40px;
  }
  
  .details-button-containers {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 5px;
  }
  
  .details-button-containers:hover {
    font-size: large;
  }
  
  #github {
    width: 30px;
    margin-left: 8px;
  }
  
  #external-link {
    width: 25px;
    margin-left: 8px;
  }
  
}